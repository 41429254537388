import React from "react";
import EventDay from "./eventDay";

const EventCard = ({ event }) => {
  // console.log("EVENT :");
  // console.log(event);
  // console.log("https://secure.webtic.it/api/" + event.Picture);
  return (
    <div className="bg-gray-300 rounded-lg overflow-hidden my-16">
      <div className="md:flex clear-both w-full bg-gray-700 p-4">
        <div className="p-4 m-auto md:w-1/2">
          <div className="text-center md:text-left font-bold text-gray-200 text-3xl md:text-3xl leading-none mb-4">
            {event.title}
          </div>
          <div className="text-justify text-gray-200 text-sm leading-none">
            {event.description}
          </div>
        </div>
        <div className="p-4 m-auto md:w-1/2">
          <img
            src={"https://secure.webtic.it/api/" + event.picture}
            alt=""
            className="object-contain object-center h-64 w-full rounded-lg"
          />
        </div>
      </div>
      <div className="clear-both bg-gray-700 p-4">
        <div className="">
          {event.days.map((day) => {
            {
              /* console.log(event.EventId + ' - ' + day.Day.substring(0, 10)); */
            }
            return (
              <EventDay
                key={day.day.substring(0, 10)}
                day={day}
                eventId={event.eventId}
                isStreaming={event.isStreaming}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EventCard;
