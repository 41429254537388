import React from 'react'
import EventCard from './eventCard'

const EventsList = ({events}) => {
  // console.log("EVENTS LIST :");
  // console.log(events);

  events.sort((a, b) => {
    if (a.days[0].day < b.days[0].day) return -1;
    if (a.days[0].day > b.days[0].day) return 1;
    return 0
  })

  return (
    <div className="m-6 md:max-w-xl lg:max-w-3xl md:mx-auto">
      {events.map((event) => {
        {/* console.log(event.EventId); */}
        return (
        <EventCard key={event.eventId} event={event} />)
      }
      )}
    </div>
  )
}

export default EventsList;
