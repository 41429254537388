import React, { useState } from "react";

const Header = () => {
  // const isBrowser = typeof window !== `undefined`;

  // const [visible, setVisible] = useState(true);
  // const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  // function getScrollPosition({element, window}) {
  //   if(!isBrowser) return {x: 0, y: 0};

  //   const target = element ? element.current : document.body;
  //   const position = target.getBoundingClientRect();
  // }

  return (
    <header className="fixed top-0 w-full z-10 shadow-xl">
      <nav>
        <div className="bg-gray-200">
          <div className="flex container max-w-6xl mx-auto">
            <div className="w-1/3 md:w-1/2 lg:w-2/3 my-auto ml-3">
              {/* <SideDrawerToggleButton /> */}
              <a href="https://cinemabergamo.it">
                <div className="inline-block align-middle">
                  <span className="font-bold text-2xl md:text-4xl text-gray-700 font-sans text-right block leading-none">
                    Cinema
                  </span>
                  <span className="font-bold text-md md:text-lg text-gray-700 font-sans text-right block leading-none">
                    Bergamo
                  </span>
                </div>
              </a>
            </div>
            <div className="w-2/3 md:w-1/2 lg:w-1/3 bg-gray-700 p-2">
              <p className="uppercase font-bold text-white  text-base md:text-lg text-right my-auto">
                Prevendita
              </p>
              <div>
                <HeaderButton
                  color="bg-red-500"
                  url="https://www.webtic.it/#/shopping?action=loadLocal&localId=5410"
                  text="Capitol"
                />
                <HeaderButton
                  color="bg-blue-500"
                  url="https://www.webtic.it/#/shopping?action=loadLocal&localId=5409"
                  text="Gavazzeni"
                />
                <HeaderButton
                  color="bg-orange-500"
                  url="https://www.webtic.it/#/shopping?action=loadLocal&localId=5409"
                  text="Teatro Tenda"
                />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

const SideDrawerToggleButton = () => {
  return (
    <div className="inline-block align-middle mr-5">
      <button className="focus:outline-none flex flex-col justify-between h-6">
        <div className="w-8 h-1 bg-gray-700"></div>
        <div className="w-8 h-1 bg-gray-700"></div>
        <div className="w-8 h-1 bg-gray-700"></div>
      </button>
    </div>
  );
};

const HeaderButton = ({ color, url, text }) => {
  return (
    <div
      className={
        "inline-block rounded-lg text-white text-center text-sm md:text-lg ml-2 md:ml-3 float-right " +
        color
      }
    >
      <a className="block p-2 md:p-3" target="_blank" href={url}>
        {text}
      </a>
    </div>
  );
};

export { HeaderButton };
export default Header;
