import React from "react";
import EventPerformance from "./eventPerformance";

const EventDay = ({ day, eventId, isStreaming }) => {
  let dayStr = day.day.substring(8, 10);
  let monthInt = parseInt(day.performances[0].startTime.substring(5, 7)) - 1;
  // console.log(monthInt);
  let months = [
    "gen",
    "feb",
    "mar",
    "apr",
    "mag",
    "giu",
    "lug",
    "ago",
    "set",
    "ott",
    "nov",
    "dic",
  ];

  return (
    <div className="m-4">
      <div className="">
        <div className="flex rounded-lg bg-gray-100">
          <div className="w-1/4 rounded-l-lg bg-gray-500 flex flex-col">
            <div className="my-auto text-center text-white p-2">
              <p className="text-4xl font-bold">{dayStr}</p>
              <p className="text-xl font-bold uppercase">{months[monthInt]}</p>
            </div>
          </div>
          <div className="w-3/4 p-2 my-auto">
            {day.performances.map((performance) => (
              <EventPerformance
                key={eventId + "-" + performance.performanceId}
                performance={performance}
                eventId={performance.eventId}
                isStreaming={isStreaming}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDay;
