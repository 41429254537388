import React from "react";
import { iconsSVG } from "../assets/icons";

const EventPerformance = ({ performance, eventId, isStreaming }) => {
  const hostSpecs = getHostSpecs(performance);

  return (
    <a
      target="_blank"
      href={hostSpecs.eventURL + hostSpecs.hostId + "/" + eventId}
    >
      <div
        className={
          "float-left m-1 rounded-lg p-2 text-sm text-white " +
          hostSpecs.cssColor
        }
      >
        <div className="block text-lg font-bold text-center">{performance.startTime.substring(11, 16)}</div>
        <div className="block justify-evenly mx-auto">
          <div className="inline-block float-left my-auto leading-none text-xs">{hostSpecs.icon}</div>
          {/* <div className="inline-block float-right">
            {isStreaming ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 block m-auto"
                viewBox={iconsSVG.play.viewBox}
                title="streaming"
              >
                <path d={iconsSVG.play.path} fill="white" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 block m-auto"
                viewBox={iconsSVG.chair.viewBox}
                title="in struttura"
              >
                <path d={iconsSVG.chair.path} fill="white" />
              </svg>
            )}
          </div> */}
        </div>
      </div>
    </a>
  );
};

export default EventPerformance;

const getHostSpecs = (performance) => {
  const specsGavazzeni = {
    icon: "Gavazzeni",
    cssColor: "bg-blue-500",
    eventURL:
      "https://secure.webtic.it/angwt/webtic.aspx?pu=aHR0cHM6Ly93d3cud2VidGljLml0LyMvc2hvcHBpbmc/YWN0aW9uPWxvYWRMb2NhbCZsb2NhbElkPTU0MDk=&lng=it&lid=5409&tpl=blue&lvs=bnVsbA==&kid=33#/event/it/33/",
    hostId: "5409",
  };
  const specsCapitol = {
    icon: "Capitol",
    cssColor: "bg-red-500",
    eventURL:
      "https://secure.webtic.it/angwt/webtic.aspx?pu=aHR0cHM6Ly93d3cud2VidGljLml0LyMvc2hvcHBpbmc/YWN0aW9uPWxvYWRMb2NhbCZsb2NhbElkPTU0MTA=&lng=it&lid=5410&tpl=blue&lvs=bnVsbA==&kid=33#/event/it/33/",
    hostId: "5410",
  };
  const specsTeatroTenda = {
    icon: "Teatro Tenda",
    cssColor: "bg-orange-500",
    eventURL:
      "https://secure.webtic.it/angwt/webtic.aspx?pu=aHR0cHM6Ly93d3cud2VidGljLml0LyMvc2hvcHBpbmc/YWN0aW9uPWxvYWRMb2NhbCZsb2NhbElkPTU0MDk=&lng=it&lid=5409&tpl=blue&lvs=bnVsbA==&kid=33#/event/it/33/",
    hostId: "5409",
  };
  const specsDefault = {
    icon: "-",
    cssColor: "bg-gray-500",
    eventURL: "#",
    hostId: "",
  };

  if (new RegExp("GAVAZZENI").test(performance.screen)) return specsGavazzeni;
  if (new RegExp("SALA [0-9]{1}( VIRTUALE)?").test(performance.screen))
    return specsCapitol;
  if (new RegExp("TEATRO TENDA").test(performance.screen))
    return specsTeatroTenda;
  return specsDefault;
};
