import React, { useState } from "react";
import { iconsSVG } from "../assets/icons";

const Footer = () => {
  // const isBrowser = typeof window !== `undefined`;

  // const [visible, setVisible] = useState(true);
  // const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  // function getScrollPosition({element, window}) {
  //   if(!isBrowser) return {x: 0, y: 0};

  //   const target = element ? element.current : document.body;
  //   const position = target.getBoundingClientRect();
  // }

  // font-old text-xl md:text-4xl text-gray-700 font-sans text-right block leading-none

  return (
    <footer className="fixed bottom-0 w-full z-10 shadow-xl">
      <div className="bg-gray-700 border-solid border-t-2">
        <div className="max-w-4xl m-auto">
          <div className="m-auto p-1">
            <div className="flex container inline-block content-start">
              {/* <FooterButton
                color="bg-gray-500"
                text="presso struttura"
                icon={iconsSVG.chair}
              />
              <FooterButton
                color="bg-gray-500"
                text="streaming"
                icon={iconsSVG.play}
              /> */}
              <FooterButton
                color="bg-blue-500"
                text="cineteatro Gavazzeni"
                // textIcon="G"
              />
              <FooterButton
                color="bg-red-500"
                text="cinema Capitol"
                // textIcon="C"
              />
              <FooterButton
                color="bg-orange-500"
                text="teatro Tenda"
                // textIcon="T"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const FooterButton = ({ color, url, text, icon, textIcon }) => {
  return (
    <div
      title={text}
      className={
        "w-1/3 flex flex-col my-auto rounded-lg text-white text-center text-xs m-1 p-1 " +
        color
      }
    >
      <div className="">
        <a target="_blank" href={url}>
          {icon ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3 block m-auto fill-current text-white"
              viewBox={icon.viewBox}
            >
              <path d={icon.path} />
            </svg>
          ) : (
            ""
          )}
          {textIcon ? (
            <div className="block font-bold text-sm align-middle leading-none">
              {textIcon}
            </div>
          ) : (
            ""
          )}
          <div className="block align-middle m-auto leading-none text-sm md:text-lg">{text}</div>
        </a>
      </div>
    </div>
  );
};

export { FooterButton };
export default Footer;
